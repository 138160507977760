<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="3">
        <b-card header="Гэрээний дугаар авах">
          <b-form @submit.stop.prevent="onSubmit">
            <b-form-group
              id="input-group-payDescription"
              label="Гаргах гэрээний тоо"
              label-for="input-payDescription"
            >
              <money
                id="input-fee"
                class="form-control"
                v-model="contractCount"
                type="text"
                required
                placeholder="тоо"
                :precision="0"
                suffix=""
              ></money>
            </b-form-group>
            <b-form-group
              id="input-group-payDescription"
              label="Тайлбар"
              label-for="input-payDescription"
            >
              <b-form-textarea
                id="input-payDescription"
                v-model="description"
                required
                placeholder="Тайлбар"
              ></b-form-textarea>
            </b-form-group>
            <b-button type="submit" variant="success" class="pull-left"
              >Хадгалах</b-button
            >
          </b-form>
        </b-card>
      </b-col>
      <b-col sm="9">
        <c-table
          :url="'AdminWebService/get_contract_number_list'"
          :fields="columns"
          :export="true"
          :orderBy="'date'"
          :orderDirection="'DESC'"
          hover
          bordered
          caption=""
        ></c-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data: function() {
    return {
      contractCount: 0,
      description: "",
      columns: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Огноо",
          key: "createdDate",
          sortable: true,
          sort_key: "date",
        },
        { label: "Гэрээний дугаар", key: "contractNumber" },
        { label: "Тоо", key: "contractCount" },
        { label: "Үүсгэсэн ажилтан", key: "createdBy" },
        { label: "Тайлбар", key: "description" },
        { label: "File", key: "contractFile" },
      ],
    };
  },
  methods: {
    onSubmit(evt) {
      this.isLoading = true;

      this.$http
        .post(
          this.$config.API_URL + "AdminWebService/get_contract_number_data",
          {
            contract_count: this.$data.contractCount,
            description: this.$data.description,
          },
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
        )
        .then(
          (response) => {
            //console.log(response);
            this.isLoading = false;
            window.location =
              "https://admin.numur.mn/contract/" + response.data.fileName;
            this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
            this.$data.contractCount = 0;
            this.$data.description = "";
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
